export interface ICliente {
  clave: string,
  nombres: string,
  apellidos: string,
  telefono_uno: string,
  telefono_dos: string,
  telefono_tres: string,
  fecha_alta: Date,
  requiere_factura: number,
  rfc: string,
  domicilio: string,
  numero_exterior: string,
  numero_interior: string,
  codigo_postal: string,
  colonia: string,
  municipio: string,
  idEstado: number,
  correo_electronico: string,
  idFormaContactoInicial: number,
  FormaContactoInicialOtro: string,
  idDistribuidora: string,
  numero_cliente: string,
  idTipoCliente: number,
  estatus: number,
  [key: string]: any; // allows any other dynamic key-value pairs
}
export const ClienteDefault = {
  id: 0,
  clave: '',
  nombres: '',
  apellidos: '',
  telefono_uno: '',
  telefono_dos: '',
  telefono_tres: '',
  fecha_alta: new Date(),
  requiere_factura: 0,
  rfc: '',
  domicilio: '',
  numero_exterior: '',
  numero_interior: '',
  codigo_postal: '',
  colonia: '',
  municipio: '',
  idEstado: 0,
  correo_electronico: '',
  idFormaContactoInicial: 0,
  FormaContactoInicialOtro: '',
  idDistribuidora: '',
  numero_cliente: '',
  idTipoCliente: 0,
  estatus: 1
}
export interface IClienteError {
  clave: string,
  nombres: string,
  apellidos: string,
  telefono_uno: string,
  telefono_dos: string,
  telefono_tres: string,
  fecha_alta: Date,
  requiere_factura: string,
  rfc: string,
  domicilio: string,
  numero_exterior: string,
  numero_interior: string,
  codigo_postal: string,
  colonia: string,
  municipio: string,
  idEstado: string,
  correo_electronico: string,
  idFormaContactoInicial: string,
  FormaContactoInicialOtro: string,
  idDistribuidora: string,
  numero_cliente: string,
  idTipoCliente: string,
  estatus: string
}
export const ClienteError = {
  clave: '',
  nombres: '',
  apellidos: '',
  telefono_uno: '',
  telefono_dos: '',
  telefono_tres: '',
  fecha_alta: new Date(),
  requiere_factura: '',
  rfc: '',
  domicilio: '',
  numero_exterior: '',
  numero_interior: '',
  codigo_postal: '',
  colonia: '',
  municipio: '',
  idEstado: '',
  correo_electronico: '',
  idFormaContactoInicial: '',
  FormaContactoInicialOtro: '',
  idDistribuidora: '',
  numero_cliente: '',
  idTipoCliente: '',
  estatus: '',
}