import { Table, TableContainer, TableHead, TableRow, TableBody } from "@mui/material";
import { SwitchContainerGTS } from "../atoms/Controls/SwitchGTS";
import { DialogGTSList } from "../molecules/DialogGTS";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { StyledTableCell, StyledTableRow } from "../atoms/tables/TableStyles";
import ButtonGST from "../atoms/Controls/ButtonGTS";
import { UrlSiteBase } from "../Global"
import { useState, useEffect } from "react";

let { UrlBase } = UrlSiteBase();
let lstCantidades = [0,1,2,3,4,5,6,7,9,10,15,20,25,30,35,40,45,50,55,60,65,70,75,80,85,90,95,100,150,200,250,300,350,400,450,500,550,600,650,700,750,800,850,900,950,1000];

export const ListProductGTS = (props: any) => {
    const [productos, setProductos] = useState<any>([]);
    const [openDialogList, setOpenDialogList] = useState<boolean>(false);
    const [useMaxValue, setUseMaxValue] = useState<boolean>(false);
    const [selCantProd, setSelCantProd] = useState<number>(0);
    const [maxValueLst, setMaxValueList] = useState<number>(-1);
    const [titleLstProd, setTitleLstProd] = useState<string>('');
    const [listDiag, setListDiag] = useState<any>([]);
    const [itemId, setItemId] = useState<any>({});
    const screen = props?.screen ? props?.screen : "productos";
    const subScreen = props?.subScreen ? props?.subScreen : "";
    const setProductAdd = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, row: any) => {
        event.stopPropagation();
        let cantCurr = row.cantidad ? parseInt(row.cantidad) + 1 : 1
        if (useMaxValue && cantCurr >row.maxCantidad)
            return; 
        row.cantidad = cantCurr;
        setProductos([...productos]);
        props?.handleSelectedClick(productos.filter((item: any) => item.cantidad > 0));
        
    }
    const setProductRest = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, row: any) => {
        event.stopPropagation();
        if (row.cantidad && row.cantidad > 0){
            row.cantidad = parseInt(row.cantidad) - 1;
            setProductos([...productos]);
        }
        props?.handleSelectedClick(productos.filter((item: any) => item.cantidad > 0));
    }
    const handleClickItem = (row: any) => {
        setItemId(row.id);
        setTitleLstProd(row?.nombre + ' - ' + row?.presentacion);
        setOpenDialogList(true);
        if (useMaxValue) 
            setMaxValueList(row?.maxCantidad);
    }
    const handleCloseDialogList = () => {
        setOpenDialogList(false);
    }
    const handleChangeLst = (value: number) => {
        productos.find((item: any) => item.id === itemId).cantidad = value;
        setProductos([...productos]);
        setOpenDialogList(false);
        setSelCantProd(value);
        props?.handleSelectedClick(productos.filter((item: any) => item.cantidad > 0));
    }
    const formatObject = new Intl.NumberFormat('en-us', {minimumFractionDigits: 2});
    const numFormat = (num: number) => {
        return formatObject.format(num)
    }
    useEffect(() => {
        let lstEmpaques = lstCantidades.map((item: number) => {
            return { 'value': item, 'label': item + (item > 1 ? ' Empaques' : ' Empaque') };
        });
        if (props.useMaxValue !== undefined)
            setUseMaxValue(props.useMaxValue);
        setListDiag(lstEmpaques);
    },[]);
    useEffect(() => {
        setProductos(props.productos);
    }, []);
    
    if (screen === "venta-busqueda") {
        return (
            <div id="products-container">
                <ul className="list-products">
                {productos?.map((row:any) => {
                    return (
                    <li key={"item-product-" + row?.id} className="item-product" onClick={() => handleClickItem(row)}>
                        <div className="image">
                            <img src={ row?.imagenUrl ? UrlBase + "assets/productos/" + row?.imagenUrl : UrlBase + "assets/productos/cookie-default-gts.svg"} />
                        </div>
                        <div className="text-product">{row?.nombre}</div>
                        <div className="presentation">{row?.presentacion}</div>
                        <div className="price">${ numFormat(row?.precioVenta) }</div>
                        <div className="controls">
                            <button className="add-product" onClick={(event) => setProductAdd(event, row)}>+</button>
                            <button className="minus-product" onClick={(event) => setProductRest(event, row)} >-</button>
                            <div className="cantity">{row?.cantidad ? row?.cantidad : 0}</div>
                        </div>
                    </li>
                )})}
                </ul>
                <DialogGTSList title={titleLstProd} open={openDialogList} handleClose={handleCloseDialogList} list={listDiag} selected={selCantProd} maxValue={maxValueLst} handleChange={handleChangeLst} />
            </div>
        );
    }
    const getHeadCantPesoScreen = (screen: string) => {
        switch (screen) {
            case "venta":
                return <StyledTableCell align="center">Cantidad</StyledTableCell>;
            case "venta-almacenaje":
                return <StyledTableCell align="center">Cantidad a Almacenar</StyledTableCell>;
            default:
                return <StyledTableCell align="center">Peso</StyledTableCell>;
        }
    };
    const getHeadPrecioVenta = (screen: string) => {
        switch (screen) {
            case "venta":
                return <StyledTableCell align="right">Precio</StyledTableCell>;
            case "productos":
                return <StyledTableCell align="right">Precio Venta</StyledTableCell>;
            default:
                return <></>;
        }
    }
    const getCellCantPesoScreen = (screen: string, row: any) => {
        switch (screen) {
            case "venta":
            case "venta-almacenaje":
                return <StyledTableCell align="center">{row?.cantidad}</StyledTableCell>;
            default:
                return <StyledTableCell align="right" className="movile-hidden">{row?.peso}</StyledTableCell>;
        }
    };
    const getHeadActions = (screen: string, subScreen: string) => {
        switch (screen) {
            case "productos":
            case "venta-almacenaje":
                return subScreen !== "resume" ? <StyledTableCell align="right">Acciones</StyledTableCell> : <></>;
            default:
                return <></>;
        }
    }
    const getCellActions = (screen: string, subScreen: string, row: any) => {
        switch (screen) {
            case "productos":
                return <StyledTableCell align="right" className="icons-actions" ><ButtonGST onClick={() => props.handleClick(row?.id)}><EditIcon /></ButtonGST></StyledTableCell>;
            case "venta-almacenaje":
                return subScreen !== "resume" ? 
                    <StyledTableCell align="right" className="icons-actions" ><ButtonGST onClick={() => props.handleDeleteClick(row?.id)}><DeleteIcon /></ButtonGST></StyledTableCell> : <></>;
            default:
                return <></>;
        }
    }
    return (
        <TableContainer >
        <Table className="table-gts">
          <TableHead>
            <TableRow>
              <StyledTableCell>ID </StyledTableCell>
              <StyledTableCell>{screen === "venta" || screen === "venta-almacenaje" ? "Nombre / Presentación" : "Nombre"}</StyledTableCell>
              {screen === "productos" ? <StyledTableCell>Presentación</StyledTableCell> : <></>}
              {getHeadCantPesoScreen(screen)}
              {getHeadPrecioVenta(screen)}
              {screen === "venta" ? <StyledTableCell align="right">Importe</StyledTableCell> : <></>}
              {screen === "productos" ? <StyledTableCell align="right">Activo</StyledTableCell> : <></>}
              {getHeadActions(screen, subScreen)}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.productos?.map((row:any) => {
                if (screen === "venta-almacenaje" && row.cantidad === 0)
                    return null;
                return <StyledTableRow key={Math.random()}>
                    <StyledTableCell component="th" scope="row">
                    {row?.id}
                    </StyledTableCell>
                    <StyledTableCell>{screen === "venta" || screen === "venta-almacenaje" ? row?.nombre + ' - ' + row?.presentacion : row?.nombre}</StyledTableCell>
                    {screen === "productos" ? <StyledTableCell>{row?.presentacion}</StyledTableCell> : <></>}
                    {getCellCantPesoScreen(screen, row)}
                    {screen !== "venta-almacenaje" ? <StyledTableCell align="right">${row?.precioVenta}</StyledTableCell> : <></>}
                    {screen === "venta" ? <StyledTableCell align="right">${ numFormat(row?.cantidad * row?.precioVenta)}</StyledTableCell> : <></>}
                    {screen === "productos" ? <StyledTableCell align="right"><SwitchContainerGTS checked={row?.estatus === 1}  /></StyledTableCell> : <></>}
                    {getCellActions(screen, subScreen, row)}
                </StyledTableRow>
            })}
          </TableBody>
        </Table>
      </TableContainer>
    )
};
export default ListProductGTS
/*
<li className="item-product">
    <div className="image"><img src={ UrlBase + "assets/productos/galleta-chispas-chocolate.svg"} /></div>
    <div className="text-product">Galleta Chispas Chocolate</div>
</li>
<li className="item-product">
    <div className="image"><img src={ UrlBase + "assets/productos/galleta-cuadrada-nuez.svg"} /></div>
    <div className="text-product">Galleta de Nuez</div>
</li>
<li className="item-product">
    <div className="image"><img src={ UrlBase + "assets/productos/galleta-cuadrada-azucar-glass.svg"} /></div>
    <div className="text-product">Barras con azucar glass</div>
</li>
<li className="item-product">
    <div className="image"><img src={ UrlBase + "assets/productos/galleta-rosquilla-canela.svg"} /></div>
    <div className="text-product">Rosquilla de Canela</div>
</li>
<li className="item-product">
    <div className="image"><img src={ UrlBase + "assets/productos/galleta-polvoron-naranja.svg"} /></div>
    <div className="text-product">Polvoron de Naranja</div>
</li>
<li className="item-product">
    <div className="image"><img src={ UrlBase + "assets/productos/galleta-flor-fresa.svg"} /></div>
    <div className="text-product">Galleta de Flor Mermelada de Fresa</div>
</li>
<li className="item-product">
    <div className="image"><img src={ UrlBase + "assets/productos/galleta-flor-piña.svg"} /></div>
    <div className="text-product">Galleta Flor Mermelada de Piña</div>
</li>
*/