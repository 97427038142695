import { Box, FormControl, FormHelperText, InputLabel, TextField, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ICliente, ClienteDefault, IClienteError, ClienteError } from "../../interfaces/cliente-interface";
import { getServiceData, saveServiceData } from "../../store/global-services";
import ButtonGST from "../../atoms/Controls/ButtonGTS";
import { SwitchLabelGTS } from "../../atoms/Controls/SwitchGTS";
import { UrlSiteBase, isEmpty } from '../../Global';
import { IGlobalPoprop } from "../../interfaces/global-interface";

let { UrlBase } = UrlSiteBase();

const AgregarCliente = (globalProp?: IGlobalPoprop) => {
  const { idCliente } = useParams();
  const [cliente, setCliente] = useState<ICliente>(ClienteDefault);
  const [error, setError] = useState<IClienteError>(ClienteError);
  const [isActive, setIsActive] = useState<boolean>(idCliente ? (cliente?.estatus === 1 ? true : false) : true);
  const [isRequiereFactura, setRequiereFactura] = useState<boolean>(idCliente ? (cliente?.requiere_factura === 1 ? true : false) : false);
  const [estados, setEstados] = useState<any>([]);
  const [tiposClientes, setTiposClientes] = useState<any>([]);
  const [formasContactos, setFormasContactos] = useState<any>([]);
  
  const navigate = useNavigate();

  const handleInputTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = event.target;
    setCliente(cliente => ({ ...cliente, [id]: value }));
  }
  const handleInputNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = event.target;
    if (isNaN(Number(value)))
      return;
    setCliente(cliente => ({ ...cliente, [id]: value }));
  }
  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    const { name, value } = event.target;
    setCliente(cliente => ({...cliente, [name]: value }));
  }
  const handleCheckbox = () => {
    setIsActive(!isActive);
    setCliente(cliente => ({ ...cliente, ['estatus']: !isActive ? 1 : 0 }));
  }
  const handleCheckRequiereFactura = () => {
    setRequiereFactura(!isRequiereFactura);
    setCliente(cliente => ({ ...cliente, ['requiere_factura']: !isRequiereFactura ? 1 : 0 }));
  }

  const guardarCliente = () => {
    saveServiceData( {nombre: 'cliente', id: idCliente, datos: cliente}).then( data => {
      if (data.success) {
        navigate(UrlBase + 'Clientes');
        if (globalProp && globalProp.snackHandleOpen)
          globalProp.snackHandleOpen({open: true, message: data.message});
      }
      else {
        setError(ClienteError);
        for (const [key, value] of Object.entries(data.message)) {
          setError(error => ({...error, [key]: value}));
        }
      }
    });
  };
  useEffect(() => {
    getServiceData({ nombre: 'estados', parametros: { estatus: 1 }}).then(data => {
      if (data.success && data.estados)
        setEstados([...data.estados]);
    });
    getServiceData({ nombre: 'tipos-clientes', parametros: { estatus: 1 }}).then(data => {
      if (data.success && data.tiposClientes)
        setTiposClientes([...data.tiposClientes]);
    });
    getServiceData({ nombre: 'formas-contactos', parametros: { estatus: 1 }}).then(data => {
      if (data.success && data.formasContactos)
        setFormasContactos([...data.formasContactos]);
    });
  }, []);
  
  useEffect(() => {
    if (idCliente && estados.length > 0 && tiposClientes.length > 0 && formasContactos.length > 0) {
      getServiceData({id: idCliente, nombre : 'cliente' }).then(data => {
        if (data.success && data.cliente){
          setCliente(cliente => ({ ...cliente, ...data.cliente }));
          setIsActive(data.cliente.estatus === 1 ? true : false);
          setRequiereFactura(data.cliente.requiere_factura === 1 ? true : false);
        }
      });
    }
  }, [estados, tiposClientes, formasContactos]);
  const getSafeValue = (field: any) => {
    return field === undefined || field === null ? '' : field;
  };
  return (
    <>
      <Box className="form-gst" component="form" noValidate autoComplete="off">
        <div className="panel-inputs">
          <TextField id="id" value={cliente?.id} label="Id" aria-readonly={true} disabled={true} color="secondary" />
          <TextField id="clave" onChange={handleInputTextChange} value={cliente?.clave} label="Clave" aria-readonly={true} color="secondary" error={error.clave !== ''}  helperText={error.clave} />
          <TextField id="nombres" onChange={handleInputTextChange} value={cliente?.nombres} label="* Nombre(s) / Alias" color="secondary" error={error.nombres !== ''}  helperText={error.nombres} />
          <TextField id="apellidos" onChange={handleInputTextChange} value={getSafeValue(cliente?.apellidos)} label="Apellido(s) / Razón Social" color="secondary" error={error.apellidos !== ''}  helperText={error.apellidos} />
          <TextField id="correo_electronico" onChange={handleInputTextChange} value={getSafeValue(cliente?.correo_electronico)} label="* Correo Electrónico" error={error.correo_electronico !== ''}  helperText={error.correo_electronico} InputLabelProps={{ shrink: isEmpty(cliente?.correo_electronico) }} />
          <TextField id="telefono_uno" onChange={handleInputNumberChange} value={getSafeValue(cliente?.telefono_uno)} label="* Teléfono fijo" color="secondary" error={error.telefono_uno !== ''}  helperText={error.telefono_uno} />
          <TextField id="telefono_dos" onChange={handleInputNumberChange} value={getSafeValue(cliente?.telefono_dos)} label="Celular uno" color="secondary" error={error.telefono_dos !== ''}  helperText={error.telefono_dos} />
          <TextField id="telefono_tres" onChange={handleInputNumberChange} value={getSafeValue(cliente?.telefono_tres)} label="Celular dos" color="secondary" error={error.telefono_tres !== ''}  helperText={error.telefono_tres} />
          <FormControl className="check-control" error={error.requiere_factura !== ''}>
            <SwitchLabelGTS className="double" label="Requiere facturación" id="requiere_factura" checked={isRequiereFactura} onChange={handleCheckRequiereFactura} />
          </FormControl>
          <FormControl className="check-control" error={error.estatus !== ''}>
            <SwitchLabelGTS label="Activo" id="estatus" checked={isActive} onChange={handleCheckbox} />
          </FormControl>
        </div>  
        <div className={"panel-inputs " + (isRequiereFactura ? 'active' : ' inactive')}>
          <TextField id="rfc" onChange={handleInputTextChange} value={getSafeValue(cliente?.rfc)} label="RFC" color="secondary" error={error.rfc !== ''}  helperText={error.rfc} />
          <TextField id="domicilio" onChange={handleInputTextChange} value={getSafeValue(cliente?.domicilio)} label="Domicilio" color="secondary" error={error.domicilio !== ''}  helperText={error.domicilio} />
          <TextField id="numero_exterior" onChange={handleInputTextChange} value={getSafeValue(cliente?.numero_exterior)} label="Numero exterior" color="secondary" error={error.numero_exterior !== ''}  helperText={error.numero_exterior} />
          <TextField id="numero_interior" onChange={handleInputTextChange} value={getSafeValue(cliente?.numero_interior)} label="Numero interior" color="secondary" error={error.numero_interior !== ''}  helperText={error.numero_interior} />
          <TextField id="codigo_postal" onChange={handleInputNumberChange} value={getSafeValue(cliente?.codigo_postal)} label="Código Postal" color="secondary" error={error.codigo_postal !== ''}  helperText={error.codigo_postal} />
          <TextField id="colonia" onChange={handleInputTextChange} value={getSafeValue(cliente?.colonia)} label="Colonia" color="secondary" error={error.colonia !== ''}  helperText={error.colonia} />
          <TextField id="municipio" onChange={handleInputTextChange} value={getSafeValue(cliente?.municipio)} label="Municipio" color="secondary" error={error.municipio !== ''}  helperText={error.municipio} />
          <FormControl className="select-control" error={error.idEstado !== ''}>
            <InputLabel id="estado-label">Estado</InputLabel>
            <Select labelId="Estado" id="estado" name="idEstado" value={getSafeValue(cliente?.idEstado)} label="Estados" onChange={handleSelectChange}>
              <MenuItem value={0}>Sin estado</MenuItem>
              {estados?.map((estado: any) => (
                <MenuItem key={"item-" + estado?.id} value={estado?.id}>{estado?.nombre}</MenuItem>
              ))}
            </Select>
            <FormHelperText>{error.idEstado}</FormHelperText>
          </FormControl>
        </div>
        <div className="panel-inputs">
          <FormControl className="select-control" error={error.idFormaContactoInicial !== ''}>
            <InputLabel id="idFormaContactoInicial-label">Forma de contacto</InputLabel>
            <Select labelId="lblFormaContactoInicial" id="idFormaContactoInicial" name="idFormaContactoInicial" value={getSafeValue(cliente?.idFormaContactoInicial)} label="Forma de contacto" onChange={handleSelectChange}>
              <MenuItem value={0}>Sin forma de contacto incial</MenuItem>
              {formasContactos?.map((formaContacto: any) => (
                <MenuItem key={"item-" + formaContacto?.id} value={formaContacto?.id}>{formaContacto?.nombre}</MenuItem>
              ))}
            </Select>
            <FormHelperText>{error.idFormaContactoInicial}</FormHelperText>
          </FormControl>
          <TextField id="FormaContactoInicialOtro" onChange={handleInputTextChange} value={getSafeValue(cliente?.FormaContactoicialOtro)} label="Otra forma de contacto" color="secondary" error={error.FormaContactoInicialOtro !== ''}  helperText={error.FormaContactoInicialOtro} />
          <FormControl className="select-control" error={error.idTipoCliente !== ''}>
            <InputLabel id="tipoCliente-label">Tipo de Cliente</InputLabel>
            <Select labelId="lblTipoCliente" id="idTipoCliente" name="idTipoCliente" value={getSafeValue(cliente?.idTipoCliente)} label="Tipo de Cliente" onChange={handleSelectChange}>
              <MenuItem value={0}>Sin tipo de cliente</MenuItem>
              {tiposClientes?.map((tipoCliente: any) => (
                <MenuItem key={"item-" + tipoCliente?.id} value={tipoCliente?.id}>{tipoCliente?.nombre}</MenuItem>
              ))}
            </Select>
            <FormHelperText>{error.idTipoCliente}</FormHelperText>
          </FormControl>
        </div>
        <div className={"panel-inputs estatus " + (idCliente ? 'active' : 'inactive')}>
          <span className="label">Distribuidora Alta: {cliente?.distribuidora !== null ? cliente?.distribuidora : 'Sin distribuidora'}</span>
          <span className="label">{", Fecha de alta: " + cliente?.fecha_alta}</span>
          
        </div>
        <div className="panel-actions">
          <ButtonGST onClick={() => navigate(UrlBase + 'Clientes')}>Cancelar</ButtonGST>
          <ButtonGST onClick={guardarCliente}>Guardar</ButtonGST>
        </div>
      </Box>
    </>
  )
}

export default AgregarCliente;