import './css/App.css';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { UrlSiteBase } from './Global';
import NavbarGTS from './molecules/Navbar/NavbarGTS';
import { Route, Routes } from "react-router-dom";
import Homepage from './screens/Homepage/Homepage';
import ScreenManager from './screens/ScreenManager';
import Logout from './screens/Logout/Logout';
import ProtectedRoute from './store/ProtectedRoute';

import NavTitlesGTS from "./molecules/Nav/NavTitlesGTS";
import { useState, useContext } from "react";
import { Snackbar } from "@mui/material";

let { UrlBase } = UrlSiteBase();
function App(): JSX.Element {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const [color, setColor] = useState<string>('');
  const [isLogin, setLogin] = useState<boolean>(false);
  
  const snackHandleOpen = (opt: any) => {
    setIsOpen(opt.open);
    setMessage(opt.message);
    setColor(opt.color);
  }
  const loginHandler = (data: any) => {
    setLogin(data.success);
  }
  const handleClose = () => {
    setIsOpen(false);
  };
  const theme = createTheme({
    typography: {
      fontFamily: 'montserratregular, Arial'
    }
  });
  return (
    <>
      <ThemeProvider theme={theme}>
        <NavbarGTS isLoggedIn={isLogin}/>
        <div className="page-container">
          <NavTitlesGTS />
          <Routes>
            <Route path={UrlBase + 'login'} element={<Homepage snackHandleOpen={snackHandleOpen} loginHandler={loginHandler} />} />
            <Route path={UrlBase} element={<Homepage snackHandleOpen={snackHandleOpen} loginHandler={loginHandler} />} />
            <Route element={<ProtectedRoute />}>
              <Route path={UrlBase + 'homepage'} element={<Homepage snackHandleOpen={snackHandleOpen} loginHandler={loginHandler} />} />
              <Route path={UrlBase + 'Usuario'} element={<ScreenManager section={"usuario"} screen={"ListadoUsuarios"} snackHandleOpen={snackHandleOpen} />} />
              <Route path={UrlBase + 'Usuario/Agregar'} element={<ScreenManager section={"usuario"} screen={"Usuario"} snackHandleOpen={snackHandleOpen} />} />
              <Route path={UrlBase + 'Usuario/Editar/:idUsuario?'} element={<ScreenManager section={"usuario"} screen={"Usuario"} snackHandleOpen={snackHandleOpen} />} />
              <Route path={UrlBase + 'Perfil'} element={<ScreenManager section={"perfil"} screen={"ListadoPerfil"} snackHandleOpen={snackHandleOpen} />} />
              <Route path={UrlBase + 'Perfil/Agregar'} element={<ScreenManager section={"perfil"} screen={"Perfil"} snackHandleOpen={snackHandleOpen} />} />
              <Route path={UrlBase + 'Perfil/Editar/:idPerfil?'} element={<ScreenManager section={"perfil"} screen={"Perfil"} snackHandleOpen={snackHandleOpen} />} />
              <Route path={UrlBase + 'Permisos'} element={<ScreenManager section={"permisos"} screen={"Permisos"} snackHandleOpen={snackHandleOpen} />} />
              <Route path={UrlBase + 'Productos'} element={<ScreenManager section={"producto"} screen={"ListadoProductos"} snackHandleOpen={snackHandleOpen} />} />
              <Route path={UrlBase + 'Producto/Agregar'} element={<ScreenManager section={"producto"} screen={"Producto"} snackHandleOpen={snackHandleOpen} />} />
              <Route path={UrlBase + 'Producto/Editar/:idProducto?'} element={<ScreenManager section={"producto"} screen={"Producto"} snackHandleOpen={snackHandleOpen} />} />
              <Route path={UrlBase + 'Distribuidoras'} element={<ScreenManager section={"distribuidora"} screen={"ListadoDistribuidoras"} snackHandleOpen={snackHandleOpen} />} />
              <Route path={UrlBase + 'Distribuidora/Agregar'} element={<ScreenManager section={"distribuidora"} screen={"Distribuidora"} snackHandleOpen={snackHandleOpen} />} />
              <Route path={UrlBase + 'Distribuidora/Editar/:idDistribuidora?'} element={<ScreenManager section={"distribuidora"} screen={"Distribuidora"} snackHandleOpen={snackHandleOpen} />} />
              <Route path={UrlBase + 'Clientes'} element={<ScreenManager section={"cliente"} screen={"ListadoClientes"} snackHandleOpen={snackHandleOpen} />} />
              <Route path={UrlBase + 'Cliente/Agregar'} element={<ScreenManager section={"cliente"} screen={"Cliente"} snackHandleOpen={snackHandleOpen} />} />
              <Route path={UrlBase + 'Cliente/Editar/:idCliente?'} element={<ScreenManager section={"cliente"} screen={"Cliente"} snackHandleOpen={snackHandleOpen} />} />
              <Route path={UrlBase + 'Clientes-Prospecto'} element={<ScreenManager section={"cliente-prospecto"} screen={"ListadoClientesProspecto"} snackHandleOpen={snackHandleOpen} />} />
              <Route path={UrlBase + 'Cliente-Prospecto/Agregar'} element={<ScreenManager section={"cliente-prospecto"} screen={"ClienteProspecto"} snackHandleOpen={snackHandleOpen} />} />
              <Route path={UrlBase + 'Cliente-Prospecto/Editar/:idCliente?'} element={<ScreenManager section={"cliente-prospecto"} screen={"ClienteProspecto"} snackHandleOpen={snackHandleOpen} />} />
              <Route path={UrlBase + 'Lista-Precios'} element={<ScreenManager section={"listado-precios"} screen={"ListadoPrecios"} snackHandleOpen={snackHandleOpen} />} />
              <Route path={UrlBase + 'Venta'} element={<ScreenManager section={"venta"} screen={"Venta"} snackHandleOpen={snackHandleOpen} />} />
              <Route path={UrlBase + 'Ventas'} element={<ScreenManager section={"venta"} screen={"ListadoVentas"} snackHandleOpen={snackHandleOpen} />} />

              <Route path={UrlBase + 'Pedido'} element={<ScreenManager section={"pedido"} screen={"ListadoPedidos"} snackHandleOpen={snackHandleOpen} />} />
              <Route path={UrlBase + 'Pedido/Editar/:idPedido?'} element={<ScreenManager section={"pedido"} screen={"Pedido"} snackHandleOpen={snackHandleOpen} />} />
              
              <Route path={UrlBase + 'Reportes/PedidoTotal'} element={<ScreenManager section={"reportes"} screen={"ReportePedidos"} snackHandleOpen={snackHandleOpen} />} />
              <Route path={UrlBase + 'logout'} element={<Logout />} />
            </Route>
          </Routes>
        </div>
        <footer>
          <div><i>Galletas nuestro sabor es tu sonrisa</i></div>
          <div>Todos los derechos reservados</div>
        </footer>
        <Snackbar autoHideDuration={3000} ContentProps={{ sx: {marginTop: "20px", background: (color ? color : "green")} }} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={isOpen} 
          onClose={handleClose} message={message} />
      </ThemeProvider>
    </>

  )
}

export default App;
