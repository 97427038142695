import { UrlSiteBase, fetchCred } from '../Global';
import { IServiceData } from "../interfaces/global-interface";
let { UrlBaseApi, UrlBase } = UrlSiteBase();
let idThSessDuration: ReturnType<typeof setTimeout>;
const onUnauthorized = () => {
    window.location.href = UrlBase + "logout";
}
const onSuccess = () => {
    if (idThSessDuration)
        clearTimeout(idThSessDuration);
    let loginDuration = parseInt(localStorage.getItem('loginDuration') || '0');
    if (loginDuration > 0){
        idThSessDuration = setTimeout(function() {  
            onUnauthorized();
        }, loginDuration * 1000);
    }
};

export const getServiceData = async(data: IServiceData) => {
    if (data?.id && /^[0-9]+$/.test(data?.id))
        return await fetchCred(`${UrlBaseApi}/${data.nombre}/${data?.id}`, null, 
            { onForbidden: data.onForbidden, onServerError: ()=> {}, onUnauthorized: onUnauthorized, onSuccess: onSuccess })
            .catch(error => { return { success: false, message: "Ocurrio un error: " + error } });
    else if (data?.parametros) {
        let params = "";
        for (const [key, value] of Object.entries(data.parametros))
            params += (params ? '&' : '?') + (`${key}=${value}`);
        return await fetchCred(`${UrlBaseApi}/${data.nombre}${params}`, null, 
            { onForbidden: data.onForbidden, onServerError: ()=> {}, onUnauthorized: onUnauthorized, onSuccess: onSuccess })
            .catch(error => { return { success: false, message: "Ocurrio un error: " + error } });
    }
    else
        return await fetchCred(`${UrlBaseApi}/${data.nombre}`, null, 
            { onForbidden: data.onForbidden, onServerError: ()=> {}, onUnauthorized: onUnauthorized, onSuccess: onSuccess })
            .catch(error => { return { success: false, message: "Ocurrio un error: " + error } });
};
export const saveServiceData = async (data: IServiceData) => {
    const params = {
        method: data?.reqMethod ? data.reqMethod : (data?.id ? 'PUT' : 'POST'),
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data.datos)
    }
    const url = data?.id ? `${UrlBaseApi}/${data.nombre}/${data.id}`: `${UrlBaseApi}/${data.nombre}`;
    return await fetchCred(url, params, { onForbidden: data.onForbidden, onServerError: ()=> {}, onUnauthorized: ()=> {}, onSuccess: onSuccess })
        .catch(error => { return { success: false, message: "Ocurrio un error: " + error } });
};
export const postServiceData = saveServiceData;